// table antd
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: unset !important;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
}
th.ant-table-cell {
    font-size: 17px;
    font-weight: bold !important;
    padding: 0 16px !important;
}
.ant-table-thead > tr > th {
    background-color:#F5F6FA !important
}
.ant-table table {
    padding:0 20px;
}
tr.ant-table-row.ant-table-row-level-0 {
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ant-table-pagination.ant-pagination {
     margin: 0 !important; 
     justify-content: center !important;
}
.ant-table-thead > tr > th {
    border-bottom: 0 !important;
}
td:first-child { border-top-left-radius: 10px; }
td:first-child { border-bottom-left-radius: 10px; }

td:last-child { border-top-right-radius: 10px; }
td:last-child { border-bottom-right-radius: 10px; }

// drop down react boostrap
.drop-status .dropdown-menu.show {
    padding: 0 !important ;
    background-color: transparent;
    border: none;

    a.dropdown-item {
        padding: 5px 10px !important;
    }

    a.dropdown-item:hover {
        background-color: unset !important;
    }
}

// tab active
.ManagerBtn.active {
    color: white;
    border-radius: 7px;
    background-image: linear-gradient(to right, #fd8023, #fd8023);
    border: none;
}

.manager-pane {
    display: none;

    &.active {
        display: block;
    }
}
