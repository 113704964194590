.ant-modal-header {
    text-align: center;

    .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
    }
}
// textarea {
//     resize: none;
// }
.disabled {
    opacity: 0.6;
}