.boderotp {
    border-bottom: 5px solid #FF1393;
    outline: none;
    font-size: 20px;
    font-weight: bold;
}
.resendOtp {
    display: flex;
    flex-direction: column;
    justify-content:center;

    span {
        text-align: center;
        font-size:20px;
        color: #FF0000;
        margin: 25px 0 5px 0;
    }

    button {
        text-decoration: underline;
        font-size:15px;

    }
}