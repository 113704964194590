.navTab.active {
    background-color: #fff;

    span {
        color: #fd8023 !important;

        span {
            border-radius: 5px;
            border: 1px solid #fd8023;
        }
    }
}