@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import '~antd/dist/antd.css';

html {
    width: 100vw;
    height: 100vh;
}

table {
    /* border-collapse: unset !important; */
    border-collapse: separate;
    border-spacing: 0px 15px !important;
    background-color: #F5F6FA;
}

html,
body {
    overflow: hidden;
}


body {
    font-family: 'Roboto', sans-serif;
    transform-origin: top left;
    width: 1600px !important;
}

#root {
    height: 100%;
    transition: all .1s;
}

.modal-open #root {
    filter: blur(2px);
}

.modal-content {
    border: none !important;
}

.modal-open .modal-backdrop {
    /* background-color: #b5b5b5; */
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* check box */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-30%);
    height: 40px;
    width: 40px;
    background-color: #eee;
}

.container:hover input~.checkmark {
    background-color: #ccc;
}

.container input:checked~.checkmark {
    background-color: #2196F3;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* readio button */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    /* line-height: 20px; */
    display: inline-block;
    color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    width: 30px;
    height: 30px;
    border: 3px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 22px;
    height: 22px;
    background: #007BFF;
    position: absolute;
    top: 4px;
    left: -4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

table.table td,
table.table th {
    padding: 0;
}

.dropdown-toggle::after {
    display: none !important;
}

.react-datepicker {
    border-radius: 8px !important;
    border: 1px solid #aaa !important;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
    font-family: Roboto, Arial, serif !important;
}

.react-datepicker__header {
    padding: 0 !important;
    background-color: #fff !important;
    border: none !important;
}

.react-datepicker__month {
    margin: 14px !important;
}

.react-datepicker__day {
    width: 30px !important;
    height: 30px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-bottom: 5px !important;
}

.react-datepicker__day-name {
    width: 30px !important;
    height: 30px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px !important;
}

.react-datepicker__day--selected {
    background-color: #AED5FF !important;
    font-weight: bold !important;

}

/* panage css */
ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    display: none !important;
}

/* set display none button flower */
button[aria-label='Open React Query Devtools'] {
    display: none !important;
}

/* modal create oder */
.modal-dialog.modal-createOder.modal-dialog-centered {
    width: 87% !important;
    height: 87% !important;
    max-width: 100%;
    max-height: 100%;
}

.modal-dialog.modal-createOder .modal-content {
    height: 100%;
}