.tabItem.active {

    border-bottom: 5px solid #fd8023;
    padding-bottom: 10px;

    span:first-child{
        color: #fd8023;
    }
}

.tabPane {
    display: none;
    &.active {
        display: block;
    }
}